import React from "react"

const Pricing = ({ data }) => {
  return (
    <>
      <section
        id="menu"
        className="menu section-bg"
        style={{ padding: "0 0 40px 0" }}
      >
        <div className="container">
          <div className="row menu-container">
            {data.list.map((item, i) => (
              <div className="col-lg-6 menu-item" key={i}>
                <img
                  src={item.image.data.full_url}
                  className="menu-img"
                  alt=""
                />
                <div className="menu-content">
                  <span className="title">{item.title}</span>
                  <span>{item.price} €</span>
                </div>
                <div className="menu-ingredients">{item.description}</div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Pricing
