import React, {useEffect, useState} from "react"
import Config from '../config'

import Container from "../components/container"
import Header from "../components/header"
import Navbar from "../components/navbar"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Pricing from "../components/pricing"

const MENU = "menu"

const PricingTemplate = ({ pageContext }) => {
  const { node } = pageContext
  const [data, setData] = useState({})
  const [pricing, setPricing] = useState([])

  const getData = async stringID => {
    const id = stringID.match(/(\d+)/)[0]
    const call = await fetch(
      `${Config.apiUrl}/items/pages/${id}?access_token=${Config.token}&fields=*.*.*`
    )
    const response = await call.json()
    setData(response.data)
  }

  const getPricing = async () => {
    const call = await fetch(
      `${Config.apiUrl}/items/pricing?access_token=${Config.token}&fields=*.*.*&filter[status]=published`
    )
    const response = await call.json()
    setPricing(response.data)
  }

  useEffect(() => {
    getData(node.id)
    getPricing()
  }, [node])

  return (
    <>
      <SEO
        title={node.meta_title}
        desc={node.meta_desc}
        keywords={node.meta_keywords}
        ogTitle={node.og_title}
        ogDesc={node.og_desc}
        ogImage={node.og_image?.data.full_url}
      />

      <Container>
        <Navbar />

        {data.banner_display && (
          <Header
            image={data.banner?.data.full_url}
            title={data.title}
            subtitle={data.subtitle}
          />
        )}

        <main id="main" style={{marginBottom: "48px"}}>
          <div className="section-bg" style={{ paddingTop: "40px" }}>
            <div className="container">
              <div
                dangerouslySetInnerHTML={{ __html: data.content }}
                className="section-title"
              ></div>
            </div>
          </div>

          {pricing.length &&
            pricing.map((item, i) => {
              return item.type === MENU ? (
                <Pricing key={item.name} data={item} />
              ) : (
                <div className="container" key={item.name}>
                  <div className="row">
                    <div
                      className="col-lg-8 offset-lg-2"
                      style={{ padding: "40px 0" }}
                    >
                      <div className="container-show-more">
                        <input id={`ch${i}`} type="checkbox" className="show-more" />
                        {/* eslint jsx-a11y/label-has-associated-control: 0 */}
                        <label htmlFor={`ch${i}`} className="show-more" style={{cursor: 'pointer'}}></label>
                        <div
                          className="text-show-more"
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                      </div>
                      {item.price && <div className="price-container">{item.price}€</div>}
                    </div>
                  </div>
                </div>
              )
            })}
        </main>

        <Footer />
      </Container>
    </>
  )
}

export default PricingTemplate
